import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'

import * as styles from '../../styles/pages/solutions.module.scss'

import mainHeader from '../../images/solutions/rpaMain.png'

import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'
import { MethodologySection } from '../../components/methodology'
import { SliderCustomers } from '../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../components/statistics'
import { FaqSection } from '../../components/faq'
import { rpaList } from '../../utils/customersList'

const methodologyItems = [
  {
    count: 1,
    title: 'Levantamento',
    description: (
      <>
        Identificados os processos com potencial de automação, via RPA, fazemos
        o seu mapeamento detalhado, identificamos oportunidades de melhoria e as
        premissas para que a automação seja bem sucedida.
      </>
    ),
  },
  {
    count: 2,
    title: 'Viabilidade',
    description: (
      <>
        Após a etapa de levantamento, analisamos a viabilidade operacional e
        financeira, apresentando os benefícios quantitativos e qualitativos do
        projeto de implementação do RPA.
      </>
    ),
  },
  {
    count: 3,
    title: 'Desenvolvimento e Licenciamento',
    description: (
      <>
        Nesta etapa, desenvolvemos as automações com base nas premissas
        levantadas. Validamos em ambiente de produção e ajustamos, caso
        necessário. Trabalhamos com um software low code e de interface
        intuitiva, com licença de uso incluída no projeto.
      </>
    ),
  },
  {
    count: 4,
    title: 'Monitoramento',
    description: (
      <>
        Oferecemos suporte 24x7 para sustentação da automação em execução e
        melhoria contínua, de acordo com as atualizações dos processos.
        Monitoramos e tratamos ocorrências para pleno funcionamento da
        automação.
      </>
    ),
  },
]

const faqItems = [
  {
    question: 'O que é automação robótica de processos?',
    answer:
      'Do acrônimo, RPA, a automação robótica de processos é uma tecnologia que permite que atividades operacionais, digitais e repetitivas sejam automatizadas por meio de softwares robôs. Os robôs são capazes de imitar as ações de um usuário, como pressionar teclas, emitir respostas, transferir dados entre aplicativos e executar transações.',
  },
  {
    question: 'Quais as principais aplicações para RPA?',
    answer:
      'É possível automatizar rotinas de trabalho em diversas áreas de negócio, tais como: processamento de notas fiscais, baixa de boletos bancários, fechamento de folha de pagamento, cálculo de impostos, cadastro de produtos, geração de relatórios, dentre outras atividades.',
  },
  {
    question: 'Quais os benefícios da automação robótica de processos?',
    answer:
      'Com a automação RPA é possível reduzir custos, ganhar velocidade no processamento de informações, minimizar erros e proporcionar maior segurança no tratamento de dados. Além disso, é uma tecnologia que requer mínima infraestrutura necessária, contando apenas com uma estação de trabalho conectada à internet.',
  },
  {
    question: 'Como a High Jump pode ajudar na jornada de automação?',
    answer:
      'Apoiamos desde empresas que estão iniciando a jornada RPA à empresas que já possuem uma solução para automação, mas que estão buscando migrar de ferramenta, por qualquer razão. Oferecemos uma solução completa que inclui a licença do software e equipe especializada para implementação.',
  },
]

const RpaPage: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="High Jump - Automação Robótica de Processos" />
      <div className={styles.solutions}>
        <Menu mainFolder="Automação Robótica de Processos" />
        <main>
          <section className={styles.intro}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={styles.mainTitle}>
              <h1>Automatize rotinas de trabalho e escale seu negócio</h1>
            </div>
            <div className={styles.subTitle}>
              <p>
                Conheça as nossas soluções RPA (Automação Robótica de Processos)
                para automação de tarefas operacionais, digitais e repetitivas.
                Eleve a produtividade, minimize erros e reduza custos. Invista o
                capital humano disponível em atividades mais estratégicas.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Otimizar processos organizacionais por meio da transformação
                  digital, utilizando ferramentas para automação robótica de
                  tarefas.
                </p>
              </div>

              <div className={styles.info}>
                <div className={styles.item}>
                  <h1>Treinamento</h1>
                  <p>
                    Fornecemos treinamentos in company para capacitação das
                    equipes para identificarem oportunidades de automação RPA em
                    suas rotinas de trabalho. O treinamento não é vinculado ao
                    uso de software específico
                  </p>
                </div>

                <div className={styles.item}>
                  <h1>Iniciativa RPA</h1>
                  <p>
                    Oferecemos consultoria para empresas que estão iniciando a
                    jornada de automação robótica de processos, desde o
                    treinamento, mapeamento e implementação ao monitoramento.
                    Trabalhamos com analistas de processos, desenvolvedores e
                    gestores de projeto, alocando uma equipe completa para a
                    transformação digital.
                  </p>
                </div>

                <div className={styles.item}>
                  <h1>Migraçāo RPA</h1>
                  <p>
                    Para empresas que já possuem uma solução em automação
                    robótica de processos e que por qualquer razão desejam
                    migrar para uma nova solução com melhor custo benefício, de
                    fácil manutenção e com metodologia ágil de implementação.
                  </p>
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para
                  implementação da tecnologia RPA em sua empresa."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={rpaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Consultoria para otimização de processos</h2>
                  <p>Evitando que erros ou redundâncias sejam automatizados</p>
                </div>
                <div className={styles.div2}>
                  <h2>Treinamento prático para as áreas de negócio</h2>
                  <p>Com o conteúdo preparatório para implementação do RPA</p>
                </div>
                <div className={styles.div3}>
                  <h2>Estudo de viabilidade de projeto</h2>
                  <p>
                    Contemplando custo, retorno sobre o investimento e payback
                  </p>
                </div>
                <div className={styles.div4}>
                  <h2>Mão de obra especializada e exclusiva</h2>
                  <p>
                    Alocação de profissionais certificados e dedicação exclusiva
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact />

          <FaqSection items={faqItems} />

          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default RpaPage
